import React, { useState, useEffect } from "react";
import PlainPage from "../../templates/plain-page";
import { firebaseFunc } from "../../components/firebase/firestore";
import { formatDate } from "../../utils/utils";
import { Link } from "gatsby";
import { saveAs } from "file-saver";

const NEW_LINE = "\r\n";

const donationToText = (name, arr) => {
  let text = "";
  if (arr.length) {
    text += `${NEW_LINE + name} donations${NEW_LINE}`;
    arr.forEach((row, i) => {
      text += `${NEW_LINE}Donation #${i + 1}${NEW_LINE}`;
      Object.keys(row)
        .filter(
          e => !["uid", "time", "reviewer_uid", "uid", "file_name"].includes(e),
        )
        .forEach(e => {
          text += `${e}: ${row[e]}${NEW_LINE}`;
        });
      text += `date: ${formatDate(
        new Date(row["time"]._seconds * 1000),
      )}${NEW_LINE}`;
    });
  }
  return text;
};

const handleDataDownload = () => {
  const button = document.getElementById("btn-download");
  button.classList.add("is-loading");

  firebaseFunc
    .httpsCallable("getUserData")()
    .then(({ data }) => {
      let text = "";

      const users = data[0];
      const points = data[1];
      const usersInfo = data[2];
      const money = data[3];
      const time = data[4];
      const courses = data[5];
      const equipment = data[6];

      text += "General info" + NEW_LINE;
      text += `first_name: ${users["first_name"]}${NEW_LINE}`;
      text += `surname: ${users["surname"]}${NEW_LINE}`;
      Object.keys(users)
        .filter(e => !["first_name", "surname"].includes(e))
        .forEach(e => {
          text += `${e}: ${users[e]}${NEW_LINE}`;
        });
      text += `signup-date: ${formatDate(
        new Date(usersInfo["signUpDate"]),
      )}${NEW_LINE}`;

      text += NEW_LINE + "Points" + NEW_LINE;
      text += `total points: ${points["points"]}${NEW_LINE}`;

      text += donationToText("Financial", money);
      text += donationToText("Time & Skills", time);
      text += donationToText("Courses & Scholarships", courses);
      text += donationToText("Tools & Equipment", equipment);

      var blob = new Blob([text], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "Your OCCASIO data.txt");
    })
    .catch(e => {
      alert("Error downloading data. " + e);
    })
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

const Content = ({ currentUser }) => {
  return (
    <div className="column is-10 is-offset-1">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        GDPR
      </h2>
      <div className="columns is-desktop">
        <div className="column">
          <h3 className="has-text-weight-bold is-size-6">
            Download a copy of your data
          </h3>
          <br />
          {currentUser ? (
            <button
              id="btn-download"
              className="button is-primary"
              onClick={handleDataDownload}
            >
              Download
            </button>
          ) : (
            <p>
              Please <Link to="/login">log in</Link> to download your data.
            </p>
          )}
          <h3 className="has-text-weight-bold is-size-6 m-t-lg">
            Remove your account
          </h3>
          <br />
          <p>
            You can request account removal by asking tech support. Please find
            contacts <Link to="/get-help">here</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

const GDPR = () => {
  return <PlainPage Content={Content} />;
};

export default GDPR;
